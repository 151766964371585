import { useQuery, UseQueryOptions } from "react-query"

export type LocationInfo = {
  name: string
  street_line1: string
  street_line2: string
  city: string
  state: string
  zipcode: string
  lat: string
  long: string
}

type TripInfo = {
  estimated_arrival: Date | string
  estimated_fare_min: 6500
  estimated_fare_max: 7500
  passengers_min: number
  passengers_max: number
  payment: string
  dropoff_location: LocationInfo
  pickup_location: LocationInfo
  notes: string
}

type DriverInfo = {
  name: string
  image: string
  bio: string
  phone: string
}

type VehicleInfo = {
  license: string
  make: string
  color: string
  image: string
}

type VibeInfo = {
  name: string
}

type RideInfo = {
  trip: TripInfo
  driver: DriverInfo
  vehicle: VehicleInfo
  vibe: VibeInfo
}

const fetchMissionDataQueryFn = async () => {
  const data: RideInfo = await require("./mission.json")

  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(data), 1000)
  }) as Promise<RideInfo>
}

const useMissionData = <TData = RideInfo>(
  options?: UseQueryOptions<RideInfo, Error, TData>
) => {
  return useQuery("mission-data", fetchMissionDataQueryFn, options)
}

export const useDriverData = () =>
  useMissionData({
    select: (data) => data.driver,
  })

export const useVehicleData = () =>
  useMissionData({
    select: (data) => data.vehicle,
  })

export const useTripData = () =>
  useMissionData({
    select: (data) => ({
      trip: data.trip,
      vibe: data.vibe,
    }),
  })
