import { format } from "date-fns"

import { LocationInfo, useTripData } from "api/api"

import BaseView from "components/BaseView/BaseView"
import Button from "components/Button/Button"
import DataPoint from "components/DataPoint/DataPoint"
import React from "react"

import infoIcon from "static/images/Info_icon.png"
import editIcon from "static/images/Edit_icon.png"
import Loader from "components/Loader/Loader"

type Props = { children?: never }

const formatCurrency = (value: number) => `$${value / 100}`

const TripOverview: React.FC<Props> = (props) => {
  const { data, isFetching } = useTripData()

  if (isFetching) {
    return <Loader />
  }

  const estimatedArrival = new Date(data!.trip.estimated_arrival)
  const minFare = formatCurrency(data!.trip.estimated_fare_min)
  const maxFare = formatCurrency(data!.trip.estimated_fare_max)

  return (
    <>
      <BaseView
        hero={
          <>
            <div className={"w-full flex-col flex justify-end h-80 px-6 mt-4"}>
              <div className="text-warmGray-800">
                <span className={"block my-16 font-optima text-3xl"}>
                  Your Trip
                </span>
                <span className={"font-light text-7xl"}>
                  {format(estimatedArrival, "H:mm")}
                </span>
                <span className={"text-3xl"}>
                  {format(estimatedArrival, "a")}
                </span>
              </div>
              <span className={"block text-sm"}>
                Estimated arrival at {data?.trip.dropoff_location.name}
              </span>
              <div className={"mt-6 flex flex-row justify-evenly space-x-3.5"}>
                <DataPoint
                  label={"Estimated Fare:"}
                  value={
                    <>
                      {minFare} - {maxFare}
                      <img
                        alt="Fare information"
                        className={"pl-1 inline"}
                        src={infoIcon}
                      />
                    </>
                  }
                />
                <DataPoint
                  label={"Passengers:"}
                  value={
                    <>
                      {data?.trip.passengers_min} - {data?.trip.passengers_max}
                    </>
                  }
                />
                <DataPoint label={"Payment:"} value={data?.trip.payment} />
              </div>
            </div>
          </>
        }
        content={
          <>
            <div className={"flex-grow pt-12"}>
              <Location data={data!.trip.pickup_location} />

              <hr className={"w-1/2 border-t my-4"} />

              <Location
                className={"font-bold"}
                data={data!.trip.dropoff_location}
              />

              <div className={"flex flex-row justify-start mt-4 items-center"}>
                <p className={"block text-sm leading-tight font-light"}>
                  {data?.trip.notes}
                </p>
                <div className={"flex-grow-0 w-20"}>
                  <img
                    alt="Edit trip notes"
                    src={editIcon}
                    className={"w-3 h-auto mx-auto"}
                  />
                </div>
              </div>
            </div>
            <Button disabled={true}>Cancel Trip</Button>
          </>
        }
      />
    </>
  )
}

const Location: React.FC<{
  className?: string
  children?: never
  data: LocationInfo
}> = ({ className = "", data }) => {
  return (
    <div className={`leading-tight text-sm ${className}`}>
      {data.name && <span className={"block"}>{data.name}</span>}
      <span className={"block"}>{data.street_line1}</span>
      {data.street_line2 && (
        <span className={"block"}>{data.street_line2}</span>
      )}
      <span className={"block"}>
        {data.city}, {data.state} {data.zipcode}
      </span>
    </div>
  )
}

export default TripOverview
