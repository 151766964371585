import { format } from "date-fns"

import { useTripData } from "api/api"

import BaseView from "components/BaseView/BaseView"
import Button from "components/Button/Button"
import DataPoint from "components/DataPoint/DataPoint"
import React from "react"

import mapIcon from "static/images/Map_icon.png"
import mapOverview from "static/images/Map_overview.png"
import Loader from "components/Loader/Loader"

type Props = { children?: never }

const TripDetails: React.FC<Props> = (props) => {
  const { data, isFetching } = useTripData()

  if (isFetching) {
    return <Loader />
  }

  const estimatedArrival = new Date(data!.trip.estimated_arrival)

  return (
    <>
      <BaseView
        hero={
          <>
            <div className={"w-full"}>
              <img className={"h-auto w-full"} alt={"Map"} src={mapOverview} />
              <img
                className={"h-11 object-fill ml-auto"}
                alt={"Map Icon"}
                src={mapIcon}
              />
            </div>
          </>
        }
        content={
          <>
            <div className={"flex-grow"}>
              <div className={""}>
                <span className={"hero-section-label"}>Your Trip</span>
                <div className={"mt-4 text-warmGray-800"}>
                  <span className={"font-light text-7xl"}>
                    {format(estimatedArrival, "H:mm")}
                  </span>
                  <span className={"text-3xl"}>
                    {format(estimatedArrival, "a")}
                  </span>
                </div>
                <span className={"block text-sm"}>
                  Estimated arrival at {data?.trip.dropoff_location.name}
                </span>
              </div>

              <div className={"mt-6 flex flex-row justify-evenly space-x-3.5"}>
                <DataPoint label={"Current Vibe"} value={data?.vibe.name} />
              </div>
            </div>
            <Button>Change Vehicle Vibe</Button>
          </>
        }
      />
    </>
  )
}

export default TripDetails
