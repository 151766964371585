import React from "react"
import { useDriverData } from "api/api"
import BaseView from "components/BaseView/BaseView"
import Button from "components/Button/Button"
import Loader from "components/Loader/Loader"

type Props = { children?: never }

const DriverDetails: React.FC<Props> = (props) => {
  const { data, isFetching } = useDriverData()

  if (isFetching) {
    return <Loader />
  }

  return (
    <>
      <BaseView
        hero={
          <>
            <div className={"w-full h-96 overflow-hidden -mt-14"}>
              {/* Hero Content */}
              <img
                className={"relative object-none object-top h-full mx-auto"}
                alt={data?.name}
                src={process.env.PUBLIC_URL + data?.image}
              />
            </div>
          </>
        }
        content={
          <>
            <div className={"flex-grow"}>
              <span className={"mt-6 hero-section-label"}>Your Driver</span>
              <div className={"mt-8"}>
                <span className={"font-light text-7xl"}>{data?.name}</span>
              </div>
              <div className={"mt-10"}>
                <hr className={"pb-2 border-t w-1/2"} />
                <p className={"text-xs text-warmGray-600"}>{data?.bio}</p>
              </div>
            </div>
            <Button disabled={true}>Contact Driver</Button>
          </>
        }
      ></BaseView>
    </>
  )
}

export default DriverDetails
