import React, { useLayoutEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { BrowserRouter } from "react-router-dom"

import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "react-query"

import RootRoutes from "RootRoutes"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
})

function App() {
  useLayoutEffect(() => {
    // tailwind vh fix/hack/tweak
    if (typeof window !== "undefined") {
      const onResize = () => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
      }

      window.addEventListener("resize", onResize)
      onResize()

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div>An error has occurred, please refresh and try again.</div>
            )}
          >
            <BrowserRouter>
              <div className="container h-full">
                <RootRoutes />
              </div>
            </BrowserRouter>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </QueryClientProvider>
  )
}

export default App
