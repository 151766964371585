import { useTripData } from "api/api"
import { format } from "date-fns"
import React from "react"
import { Link, matchPath, useLocation } from "react-router-dom"
import { paths } from "RootRoutes"

import profileIcon from "static/images/Profile_icon.png"
import vibesIcon from "static/images/Vibes_icon.png"

const Footer: React.FC<{}> = () => {
  const { data } = useTripData()
  const location = useLocation()
  const onDetails = matchPath(location.pathname, {
    path: paths.tripDetails,
  })

  return (
    <footer
      className={"w-full pb-4 pt-2 px-8 border-t mx-auto bg-warmGray-200"}
    >
      <div className={"flex justify-between items-center"}>
        <Link to="/driver">
          <img
            className={"h-8 w-auto object-fill"}
            alt={"Profile"}
            src={profileIcon}
          />
        </Link>

        <Link to="/vehicle">
          <div
            className={
              "flex flex-col items-center text-warmGray-700 overflow-hidden"
            }
          >
            <span className={"font-bold leading-none whitespace-nowrap"}>
              {data?.trip?.dropoff_location.name}
            </span>
            <span className={"leading-none text-xs"}>
              {onDetails
                ? "En route..."
                : `ETA ${format(
                    new Date(data!.trip.estimated_arrival),
                    "H:mma"
                  )}`}
            </span>
          </div>
        </Link>

        <Link to="/details">
          <img
            className={"h-8 w-auto object-fill"}
            alt={"Vibe"}
            src={vibesIcon}
          />
        </Link>
      </div>
    </footer>
  )
}

export default Footer
