import React from "react"

const Loader = () => {
  return (
    <div className={"h-full flex flex-col justify-center items-center"}>
      <span className="text-sm">Loading</span>
    </div>
  )
}

export default Loader
