import DriverDetails from "modules/DriverDetails/DriverDetails"
import TripDetails from "modules/TripDetails/TripDetails"
import TripOverview from "modules/TripOverview/TripOverview"
import VehicleDetails from "modules/VehicleDetails/VehicleDetails"
import { Switch, Route, Redirect } from "react-router-dom"

export const paths = {
  tripOverview: "/",
  driver: "/driver",
  vehicle: "/vehicle",
  tripDetails: "/details",
}

const RootRoutes = () => (
  <Switch>
    <Route exact path={paths.tripOverview} component={TripOverview} />
    <Route path={paths.vehicle} component={VehicleDetails} />
    <Route path={paths.driver} component={DriverDetails} />
    <Route path={paths.tripDetails} component={TripDetails} />
    <Redirect to={paths.tripOverview} />
  </Switch>
)

export default RootRoutes
