import React from "react"
import { useVehicleData } from "api/api"
import BaseView from "components/BaseView/BaseView"
import Button from "components/Button/Button"
import DataPoint from "components/DataPoint/DataPoint"
import Loader from "components/Loader/Loader"

type Props = { children?: never }

const VehicleDetails: React.FC<Props> = (props) => {
  const { data, isFetching } = useVehicleData()

  if (isFetching) {
    return <Loader />
  }

  return (
    <>
      <BaseView
        hero={
          <>
            <div className={"w-full h-80 overflow-hidden"}>
              <img
                className={"relative object-none object-middle h-full mx-auto"}
                alt={`${data?.color} ${data?.make}`}
                src={process.env.PUBLIC_URL + data?.image}
              />
            </div>
          </>
        }
        content={
          <>
            <div className={"flex-grow"}>
              <span className={"mt-6 hero-section-label"}>Your Vehicle</span>

              <div className={"mt-8"}>
                <span className={"font-light text-7xl"}>{data?.license}</span>
              </div>

              <div className={"mt-6 flex flex-row justify-evenly space-x-3.5"}>
                <DataPoint label={"Make / Model"} value={data?.make} />
                <DataPoint label={"Color"} value={data?.color} />
              </div>
            </div>
            <Button disabled={true}>Identify Vehicle</Button>
          </>
        }
      />
    </>
  )
}

export default VehicleDetails
