import React from "react"
import { Link, matchPath, useLocation } from "react-router-dom"
import { paths } from "RootRoutes"

const Dot: React.FC<{ children?: never; to: string }> = (props) => {
  const location = useLocation()
  const active = matchPath(location.pathname, {
    path: props.to,
    exact: true,
  })

  return (
    <Link to={props.to} className={"block"}>
      <li
        className={`rounded-full block h-2 w-2 cursor-pointer ${
          active ? "bg-gray-900" : "bg-warmGray-500"
        }`}
      ></li>
    </Link>
  )
}

const Dots: React.FC<{ children?: never; className?: string }> = ({
  className = "",
}) => {
  return (
    <div className={className}>
      <ul className="space-y-1">
        <Dot to={paths.tripOverview} />
        <Dot to={paths.driver} />
        <Dot to={paths.vehicle} />
        <Dot to={paths.tripDetails} />
      </ul>
    </div>
  )
}

export default Dots
