import React from "react"

type ButtonProps = {
  className?: string
  disabled?: boolean
} & Partial<React.HTMLAttributes<HTMLButtonElement>>

const Button: React.FC<ButtonProps> = (props) => {
  const { className = "", ...rest } = props

  return (
    <button
      className={`uppercase font-bold disabled:border w-full disabled:border-warmGray-500 disabled:text-warmGray-500 disabled:bg-warmGray-200 disabled:cursor-not-allowed p-2.5 bg-alto text-warmGray-200 text-sm tracking-wider ${className}`}
      {...rest}
    >
      {props.children}
    </button>
  )
}

export default Button
