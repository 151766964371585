import React from "react"

type Props = {
  children?: never
  label: React.ReactNode
  value: React.ReactNode
}

const DataPoint: React.FC<Props> = (props) => (
  <>
    <div className={"text-xs flex-1 border-t pt-2 text-warmGray-700"}>
      <span className={"block"}>{props.label}</span>
      <span className={"block font-bold text-sm"}>{props.value}</span>
    </div>
  </>
)

export default DataPoint
