import React from "react"

import Footer from "components/Footer/Footer"
import Dots from "components/Dots/Dots"
import { Link } from "react-router-dom"

import logo from "static/images/Alto_logo.png"

type Props = {
  hero: React.ReactNode
  content: React.ReactNode
}

const BaseView: React.FC<Props> = (props) => {
  return (
    <>
      {/* Container */}
      <div
        className={
          "border-r border-l relative flex flex-col justify-items-stretch h-full w-full sm:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto"
        }
      >
        <Dots className={"absolute right-4 top-8 z-40"} />

        {/* Hero Content */}
        {props.hero}

        <div className={"absolute top-6 left-0 right-0"}>
          <Link to="/">
            <img src={logo} alt="Alto Logo" className={"object-none mx-auto"} />
          </Link>
        </div>

        <div
          className={
            "pb-8 px-6 flex flex-col flex-grow justify-items-stretch text-warmGray-700"
          }
        >
          {/* Details / Control */}
          {props.content}
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  )
}

export default BaseView
